@import "/src/assets/styles/custom-style";
@import "/src/assets/styles/variables";
@import '/src/assets/styles/popover';
@import "/src/assets/styles/util";

body {
  height: 100%;
  // min-height: 90vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: var(--primary-font-family);
}

.bg-grey {
  background-color: #3d3d3d;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mat-mdc-form-field {
  width: 100%;
  .mat-mdc-text-field-wrapper {
    background: transparent;
  }
  .mat-mdc-form-field-error-wrapper {
    padding: 0;
  }
}

.error-message {
  color: #f44336;
}

::ng-deep {
  .verloop-widget, .verloop-button {
    display: none;
  }
  .grecaptcha-badge {
    display: none;
  }
}

.d-none {
  display: none;
}

.input-popover{
  @media(min-width: 992px){
    .popover-container{
      background: #039695;
      &.medical-popup {
        padding: 20px;
        border-radius: 10px;
        .close { text-align: center; }
        .p-title {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          width: 90%;
          font-weight: bold;
          font-size: 17px;
        }
        .p-desc { font-size: 14px; margin-top: 10px; }
        ol { padding-left: 1em; }
      }
    }
  }
  @media (max-width: 768px) {
    .popover-container{
      background: #039695;
      &.medical-popup {
        padding: 20px;
        border-radius: 10px;
        .close { text-align: center; }
        .p-title {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          width: 90%;
          font-weight: bold;
          font-size: 17px;
        }
        .p-desc { font-size: 14px; margin-top: 10px; }
        ol { padding-left: 1em; }
      }
    }
  }
}
